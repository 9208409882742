import { client } from '@/sanity/lib/client';
import imageUrlBuilder from '@sanity/image-url';

import IImage from '../interfaces/imageiInterface';

const imageHelper = (imageInfo: any): IImage => {
    const builder = imageUrlBuilder(client);
    return {
        url: builder.image(imageInfo.asset._ref).url(),
        alt: imageInfo.alt,
    };
};

export default imageHelper;
