import React, { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { IAdvInfo } from '../../assets/interfaces/advantageInterface/index';
import NextImage from '../UI/nextImage/NextImage';

import styles from './AdvantageList.module.scss';

type TAdvList = {
    advList: IAdvInfo[];
};

const AdvantageList: FC<TAdvList> = ({ advList }) => {
    const { ref, inView } = useInView({
        initialInView: false,
        triggerOnce: true,
        rootMargin: `-200px 0px 0px`,
        threshold: 0,
    });

    return (
        <ul className={styles.adv__list} ref={ref}>
            {advList.map((item, i) => {
                return (
                    <li
                        className={`${styles.adv__item} ${inView && styles['adv__item--animate']}`}
                        style={{ animationDelay: `${0.5 + (i / 4 < 1 ? 0 : 0.2)}s` }}
                        key={`${item.advName}-${i}`}>
                        <div className={styles.logo}>
                            <div className={styles.logo__wrapper}>
                                <NextImage
                                    src={item.advLogo}
                                    alt={item.advAlt}
                                    fill
                                    sizes="(max-width: 480px) 24px, 32px"
                                />
                            </div>
                        </div>
                        <p className={styles.name}>{item.advName}</p>
                        <p className={`excerpt ${styles.excerpt}`}>{item.advDescr}</p>
                    </li>
                );
            })}
        </ul>
    );
};

export default AdvantageList;
