import check from '@/public/images/check.svg';
import doc from '@/public/images/doc.svg';
import download from '@/public/images/download.svg';
import ghost from '@/public/images/ghost.svg';
import help from '@/public/images/help.svg';
import laptop from '@/public/images/laptop.svg';
import light from '@/public/images/light-dark.svg';
import responsive from '@/public/images/responsive.svg';
import settings from '@/public/images/settings.svg';
import update from '@/public/images/update.svg';
import users from '@/public/images/users-profiles.svg';
import wand from '@/public/images/wand.svg';

import { IAdvInfo } from '../interfaces/advantageInterface';

export const advInfoShort: IAdvInfo[] = [
    {
        advName: 'Ghost 5.0+',
        advDescr: `Compatible with Ghost 5.0+, our theme ensures you're working with the latest web technology standards.`,
        advLogo: ghost,
        advAlt: 'Ghost',
    },
    {
        advName: 'Responsive Design',
        advDescr:
            'Our themes have responsive design ensures seamless display and functionality across all devices.',
        advLogo: responsive,
        advAlt: 'Responsive Design',
    },
    {
        advName: 'expert team',
        advDescr:
            'Crafted by seasoned professionals, our themes bring together expertise and innovation for top-tier website performance.',
        advLogo: users,
        advAlt: 'Experts',
    },
    {
        advName: 'Dedicated Support',
        advDescr:
            'Receive dedicated support with our theme, offering you prompt and professional assistance whenever needed.',
        advLogo: help,
        advAlt: 'Support',
    },
];

export const advInfoFull: IAdvInfo[] = [
    {
        advName: 'Easy to Deploy',
        advDescr:
            'Deploy your Ghost theme effortlessly with our simple, guided process designed for rapid setup and minimal downtime.',
        advLogo: download,
        advAlt: 'Easy to Deploy',
    },
    {
        advName: 'Custom Settings',
        advDescr:
            'Customize with ease using our robust settings panel, allowing precise adjustments for layouts, fonts, and more to align with your branding."',
        advLogo: settings,
        advAlt: 'Settings',
    },
    {
        advName: 'Great Performance',
        advDescr: `Developers will appreciate our themes' clean codebase, which allows extensive customization and easy integration with other tools.`,
        advLogo: light,
        advAlt: 'Performance',
    },
    {
        advName: 'Developer Friendly',
        advDescr:
            'Enjoy peace of mind knowing you have expert support just a phone call or message away.',
        advLogo: laptop,
        advAlt: 'Developer Friendly',
    },
    {
        advName: 'Multi-purpose Design',
        advDescr:
            'Designed for versatility, our themes can be effortlessly adapted for various content types and industries, providing a flexible foundation.',
        advLogo: wand,
        advAlt: 'Multi-purpose Design',
    },
    {
        advName: 'Flexible Buying Options',
        advDescr:
            'A variety of purchasing options that cater to individual theme needs or comprehensive bundles for full access to our theme library.',
        advLogo: check,
        advAlt: 'Flexible Buying Options',
    },
    {
        advName: 'Regular Updates',
        advDescr:
            'Regular theme updates that not only introduce new features but also maintain security and compatibility with the latest Ghost updates.',
        advLogo: update,
        advAlt: 'Regular Updates',
    },
    {
        advName: 'Well Documented',
        advDescr:
            'Our themes are accompanied by detailed documentation that covers everything from initial setup to advanced customization.',
        advLogo: doc,
        advAlt: 'Documentation',
    },
];
