import bg1 from '@/public/images/backgrounds/bg1.jpg';
import bg2 from '@/public/images/backgrounds/bg2.jpg';
import bg3 from '@/public/images/backgrounds/bg3.jpg';
import bg4 from '@/public/images/backgrounds/bg4.jpg';
import bg5 from '@/public/images/backgrounds/bg5.jpg';
import bg6 from '@/public/images/backgrounds/bg6.jpg';
import bg7 from '@/public/images/backgrounds/bg7.jpg';
import bg8 from '@/public/images/backgrounds/bg8.jpg';

const bgLine = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8];

export default bgLine;
