import bgLine from './backgrounds';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import createAnimationArr from '@/src/assets/helpers/createAnimationArr';

import Button from '../../UI/button/Button';
import NextImage from '../../UI/nextImage/NextImage';
import { HeaderContext } from '../../providers/headerContextProvider/HeaderContextProvider';

import styles from './BundleSection.module.scss';

const thesisArr = [
    'All Themes',
    'Get Future Themes for Free',
    'Lifetime Updates & Access',
    'One‐time Purchase Fee',
];

const excerpt = `Receive an all-inclusive collection of current and future premium Ghost themes, 
                 complete with lifetime updates and access, all for a one-time purchase fee.`;

type TDirection = 'vertical' | 'horizontal';

const BundleSection = ({
    className,
    showLink,
    isTitle,
}: {
    className: string;
    showLink?: boolean;
    isTitle?: boolean;
}) => {
    const sizes = `(max-width: 376px) 280px, (max-width: 414px) 320px, 
                    (max-width: 480px) 360px, (max-width: 576px) 400px, 
                    (max-width: 768px) 500px, (max-width: 1024px) 23vw, 26.46vw`;

    const [direction, setDirection] = useState<TDirection>('vertical');
    const [gap, setGap] = useState(16);

    useEffect(() => {
        const handleResize = () => {
            setDirection(window.innerWidth > 768 ? 'vertical' : 'horizontal');
            setGap(window.innerWidth > 480 ? 16 : 12);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const sectionRef = useRef<HTMLElement>(null);
    const [isFullyScrolled, setIsFullyScrolled] = useState(false);
    const { setIsBundleScrolled } = useContext(HeaderContext);

    useEffect(() => {
        const handleScroll = () => {
            const section = sectionRef.current;
            if (section) {
                const { bottom } = section.getBoundingClientRect();
                const isSectionFullyVisible = bottom >= 0;
                setIsFullyScrolled(!isSectionFullyVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        isFullyScrolled ? setIsBundleScrolled(true) : setIsBundleScrolled(false);
    }, [isFullyScrolled]);

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: `-180px`,
        threshold: 0,
    });

    return (
        <section className={`${styles.bundle__section} ${className}`} ref={sectionRef}>
            <Swiper
                modules={[Autoplay]}
                direction={direction}
                className={`${styles.swiper} ${styles.swiper__left} gallery-autoplay-swiper`}
                slidesPerView={'auto'}
                initialSlide={0}
                allowTouchMove={false}
                shortSwipes={false}
                longSwipes={false}
                loop={true}
                autoplay={{
                    disableOnInteraction: false,
                    pauseOnMouseEnter: false,
                    delay: 0,
                }}
                spaceBetween={gap}
                speed={15000}>
                {bgLine.map((bg, i) => {
                    return (
                        <SwiperSlide className={styles.column__block} key={`background-left-${i}`}>
                            <NextImage
                                src={`${bg.src}`}
                                alt={`Background ${i}`}
                                fill
                                sizes={sizes}
                                priority
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <article ref={ref} className={styles.section__info}>
                <div className={styles.info__top}>
                    <h2
                        className={`${isTitle ? 'heading--1' : 'heading--2'} ${styles.heading} ${
                            inView && styles.animate__heading
                        }`}>
                        <span className={styles.word__wrapper}>
                            <span>Save&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span>Big&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span>with&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span>the&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span>Ghost&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span>Theme&nbsp;</span>
                        </span>
                        <span className={styles.word__wrapper}>
                            <span>Bundle&nbsp;</span>
                        </span>
                    </h2>
                    <p className={`excerpt ${styles.excerpt} ${inView && styles.animate__excerpt}`}>
                        {createAnimationArr(excerpt, styles.word__wrapper, 0.1, 0.01)}
                    </p>

                    <div className={styles.buttons}>
                        <Button
                            ariaLabelledby="buttonText"
                            color="blue__divided"
                            target
                            buttonUrl="https://highfivethemes.lemonsqueezy.com/checkout/buy/015cba04-c2be-4d1b-b20d-a252d477dedc">
                            <span id="buttonText">Buy theme bundle</span>
                            <span aria-hidden="true">$199</span>
                        </Button>
                        {showLink && (
                            <Button
                                buttonUrl="/bundle"
                                ariaLabel="Learn more about purchasing bundle"
                                color="standard"
                                className={styles.button}>
                                <span aria-hidden="true">Learn more</span>
                                <svg
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                        d="M6.43674 5.19931L12.7123 5.28769M12.7123 5.28769L12.8007 11.5633M12.7123 5.28769L5.28769 12.7123"
                                        stroke="#0A163C"
                                        strokeWidth="1.7"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </Button>
                        )}
                    </div>
                </div>

                <ul className={styles.thesis__list}>
                    {thesisArr.map((item, i) => {
                        return (
                            <li key={`${styles.item}-${i}`} className={`${styles.thesis__item}`}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none">
                                    <path
                                        d="M3.3125 10.625L7.25 14.5625L16.25 5.5625"
                                        stroke="white"
                                        strokeWidth="2.2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span>{item}</span>
                            </li>
                        );
                    })}
                </ul>
            </article>
            {direction === 'vertical' && (
                <Swiper
                    modules={[Autoplay]}
                    direction={direction}
                    className={`${styles.swiper} ${styles.swiper__right} gallery-autoplay-swiper`}
                    slidesPerView={'auto'}
                    initialSlide={4}
                    allowTouchMove={false}
                    shortSwipes={false}
                    longSwipes={false}
                    loop={true}
                    autoplay={{
                        disableOnInteraction: false,
                        pauseOnMouseEnter: false,
                        delay: -1,
                        reverseDirection: true,
                    }}
                    spaceBetween={gap}
                    speed={15000}>
                    {bgLine.map((bg, i) => {
                        return (
                            <SwiperSlide
                                className={styles.column__block}
                                key={`background-right-${i}`}>
                                <NextImage
                                    src={`${bg.src}`}
                                    alt={`Background ${i}`}
                                    fill
                                    sizes={sizes}
                                    priority
                                />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            )}
        </section>
    );
};

export default BundleSection;
